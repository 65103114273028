import Vue from "vue";
import "./plugins/axios";
import vSelect from "vue-select";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Echo from "laravel-echo";
import VueMask from "v-mask";

import "./styles/app.scss";

Vue.use(VueMask);

Vue.component("v-select", vSelect);

Vue.config.productionTip = false;

window.Pusher = require("pusher-js");

// "socket.boomerang.test.ut.in.ua"
// https://api.boomerang.test.ut.in.ua/api/

// const API = "https://api.boomerang.test.ut.in.ua/api";
const API = process.env.VUE_APP_API_URL;

// const SOCKET = "socket.boomerang.test.ut.in.ua";
const SOCKET = process.env.VUE_APP_SOCKET_URL;

window.Echo = new Echo({
  broadcaster: "pusher",
  key: "local",
  wsHost: SOCKET,
  disableStats: true,
  encrypted: true,
  enabledTransports: ["ws", "wss"],
  forceTLS: false,
  authEndpoint: `${API}/broadcasting/auth`,
  auth: {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  },
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
