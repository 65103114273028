import callApi from "./index";
import URL from "../constants/urlPaths";

export default {
  userLogin(data) {
    return callApi
      .request({
        method: "POST",
        url: URL.LOG_IN,
        data,
      })
      .then((res) => res.data);
  },
  userLogOut() {
    return callApi
      .request({
        method: "POST",
        url: URL.LOG_OUT,
      })
      .then((res) => res.data);
  },
};
