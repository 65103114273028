/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import Vue from "vue";
import Vuex from "vuex";
import driversApi from "../api/drivers";
import router from "../router";
import ROUTE from "../constants/routes";
import { driversList } from "../helpers/dataIteration";

Vue.use(Vuex);

const state = {
  driversList: [],
  drivers: null,
  driver: null,
  driverActiveOrder: null,
  error: {},
  errorDriver: {},
  loadingProcess: false,
  moderated: 1,
};

const mutations = {
  setDrivers: (state, payload) => {
    state.drivers = payload;
  },
  setDriver: (state, payload) => {
    state.driver = payload;
  },
  setError: (state, payload) => {
    state.error = payload;
  },
  setErrorDriver: (state, payload) => {
    state.errorDriver = payload;
  },
  setDriversList: (state, payload) => {
    state.driversList = payload;
  },
  setLoading: (state, payload) => {
    state.loadingProcess = payload;
  },
  setModerated: (state, payload) => {
    state.moderated = payload;
  },
  setDriverActiveOrder: (state, payload) => {
    state.driverActiveOrder = payload;
  },
  setNewDrivers: (state, data) => {
    if (state.drivers != null) {
      const i = state.drivers.data?.findIndex((o) => o.id == data.id);
      if (state.drivers.data[i]) {
        state.drivers.data?.splice(i, 1, data);
      } else {
        state.drivers.data?.unshift(data);
      }
    }
  },
  setDeleteDrivers: (state, data) => {
    if (state.drivers != null) {
      const i = state.drivers.data?.findIndex((o) => o.id == data.id);
      if (state.drivers.data[i]) {
        state.drivers.data?.splice(i, 1);
      }
    }
  },
};

const actions = {
  getDriversList({ commit }, payload) {
    commit("setLoading", true);
    driversApi
      .getDriversList(payload)
      .then((res) => {
        commit("setDrivers", res);
        commit("setDriversList", driversList(res?.data));
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  createDriver({ commit }, payload) {
    commit("setLoading", true);
    driversApi
      .driverCreate(payload)
      .then((res) => {
        commit("setError", {});
        router.push({
          path: ROUTE.DRIVERS_PAGE.replace(":page", 1),
        });
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  updateDriver({ commit }, { url, dataDriver }) {
    commit("setLoading", true);
    driversApi
      .driverUpdate(url, dataDriver)
      .then((res) => {
        commit("setError", {});
        router.push({
          path: ROUTE.DRIVERS_PAGE.replace(":page", 1),
        });
      })
      .catch((error) => {
        console.log(error);
        const { data } = error.response;
        commit("setError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  getDriversForOrder({ commit }, payload) {
    commit("setLoading", true);
    driversApi
      .getDriversForOrder(payload)
      .then((res) => {
        commit("setDrivers", res);
        commit("setDriversList", driversList(res?.data));
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setErrorDriver", data.errors[0]);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  getDriverActiveOrder({ commit }, payload) {
    commit("setLoading", true);
    driversApi
      .getDriverActiveOrder(payload)
      .then((res) => {
        commit("setDriverActiveOrder", res.data);
      })
      .catch((error) => {})
      .finally(() => {
        commit("setLoading", false);
      });
  },
  getDriverDetail({ commit, dispatch }, payload) {
    commit("setLoading", true);
    driversApi
      .getDriver(payload)
      .then((res) => {
        commit("setDriver", res.data);
        return res.data;
      })
      .then((data) => {
        commit("setDriverActiveOrder", null);
        if (data.free === false) {
          dispatch("getDriverActiveOrder", payload);
        }
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  deleteDriver({ commit }, payload) {
    commit("setLoading", true);
    driversApi
      .deleteDriver(payload)
      .then((res) => {})
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      });
  },
  restoreDriver({ commit }, payload) {
    commit("setLoading", true);
    driversApi
      .driverRestore(payload)
      .then((res) => {})
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      });
  },
};

const getters = {};

const drivers = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
export default drivers;
