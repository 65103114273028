/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import Vue from "vue";
import Vuex from "vuex";
import userApi from "../api/user";
import router from "../router/index";
import { setAuthData, logOut } from "../helpers/userAuth";
import ROUTE from "../constants/routes";

Vue.use(Vuex);

const state = {
  userInfo: null,
  error: {},
  loadingProcess: false,
};

const mutations = {
  setError: (state, payload) => {
    state.error = payload;
  },
  setUserInfo: (state, payload) => {
    state.userInfo = payload;
  },
  setLoading: (state, payload) => {
    state.loadingProcess = payload;
  },
};

const actions = {
  logIn({ commit }, payload) {
    commit("setLoading", true);
    userApi
      .userLogin(payload)
      .then((res) => {
        const token = res.accessToken,
          tokenTimeExpired = 0,
          user = res.data;
        if (res.password || res.email) {
          commit("setError", res);
          return;
        }
        commit("setError", {});
        commit("setUserInfo", res);
        setAuthData(token, tokenTimeExpired, user);
        router.push(ROUTE.ETHER_PAGE);
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  logOut({ commit }) {
    return userApi.userLogOut().then(() => {
      commit("setUserInfo", null);
      logOut();
      router.push(ROUTE.LOGIN_PAGE);
    });
  },
};

const getters = {};

const user = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
export default user;
