import callApi from "./index";
import URL from "../constants/urlPaths";

export default {
  getNotifications(url = "") {
    return callApi
      .request({
        method: "GET",
        url: URL.GET_NOTIFICATIONS + url,
      })
      .then((res) => res.data);
  },
  notificationRead(data) {
    return callApi
      .request({
        method: "POST",
        url: URL.NOTIFICATION_READ,
        data,
      })
      .then((res) => res.data);
  },
  notificationViewed(data) {
    return callApi
      .request({
        method: "POST",
        url: URL.NOTIFICATION_VIEWED,
        data,
      })
      .then((res) => res.data);
  },
  notificationReadAll() {
    return callApi
      .request({
        method: "POST",
        url: URL.NOTIFICATION_READ_ALL,
      })
      .then((res) => res.data);
  },
  getNotificationsTypes() {
    return callApi
      .request({
        method: "GET",
        url: URL.GET_NOTIFICATIONS_TYPES,
      })
      .then((res) => res.data);
  },
};
