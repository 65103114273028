import Vue from "vue";
import Vuex from "vuex";

import user from "./user";
import drivers from "./drivers";
import common from "./common";
import clients from "./clients";
import order from "./order";
import settings from "./settings";
import notification from "./notification";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    drivers,
    common,
    clients,
    order,
    settings,
    notification,
  },
});
