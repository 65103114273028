export default {
  LOGIN_PAGE: "/login",
  ETHER_PAGE: "/",
  ETHER_ADD_DRIVER_PAGE: "/assign/:orderId",
  ETHER_ADD_ORDER_PAGE: "/assign-order/:driverId",
  CLIENTS_PAGE: "/clients/:page",
  CLIENT_DETAIL_PAGE: "/clientDetail/:clientId",
  CLIENT_CREATE_PAGE: "/client/:process/:clientId",
  DRIVERS_PAGE: "/drivers/:page",
  DRIVER_DETAIL_PAGE: "/driverDetail/:driverId",
  DRIVER_CREATE_PAGE: "/driver/:process/:driverId",
  ORDERS_PAGE: "/orders/:page",
  ORDER_CREATE_PAGE: "/order/:process/:orderId",
  ORDER_DETAIL_PAGE: "/orderDetail/:orderId",
  ORDER_SUCCESS: "/orderSuccess/:orderId",
  SETTINGS_PAGE: "/settings",
  NOTIFICATIONS_PAGE: "/notifications/:page",
};
