/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import Vue from "vue";
import Vuex from "vuex";
import commonApi from "../api/common";

Vue.use(Vuex);

const state = {
  typesOfCars: [],
  cargoFeatures: [],
  brands: [],
  bodyTypes: [],
  paymentTypes: [],
  statuses: [],
  error: {},
  errorSendSms: [],
  limiterSendSms: false,
  top: [],
  bottom: [],
  loadingTypes: [],
  driverChoose: [],
  driverChooseAll: [],
  sendSmsModal: false,
};

const mutations = {
  setDriverChoose: (state, id) => {
    const i = state.driverChoose.indexOf(id);
    if (i !== -1) {
      state.driverChoose.splice(i, 1);
    } else {
      state.driverChoose.unshift(id);
    }
    if (state.driverChoose.length > 0) {
      state.sendSmsModal = true;
    } else {
      state.sendSmsModal = false;
    }
  },
  setDriverChooseAll: (state, payload) => {
    state.driverChoose = payload;
    state.sendSmsModal = true;
    state.driverChooseAll = ["all"];
  },
  setDriverChooseNull: (state) => {
    state.driverChoose = [];
    state.sendSmsModal = false;
    state.driverChooseAll = [];
  },
  setSendSmsModal: (state, data) => {
    state.sendSmsModal = data;
  },
  setTypesOfCars(state, payload) {
    state.typesOfCars = payload;
  },
  setPaymentTypes(state, payload) {
    state.paymentTypes = payload;
  },
  setStatuses(state, payload) {
    state.statuses = payload;
  },
  setError: (state, payload) => {
    state.error = payload;
  },
  setTop: (state, payload) => {
    state.top = payload;
  },
  setBottom: (state, payload) => {
    state.bottom = payload;
  },
  setCargoFeatures: (state, payload) => {
    state.cargoFeatures = payload;
  },
  setBrands: (state, payload) => {
    state.brands = payload;
  },
  setLoadingTypes: (state, payload) => {
    state.loadingTypes = payload;
  },
  setBodyTypes: (state, payload) => {
    state.bodyTypes = payload;
  },
  setErrorSendSms: (state, payload) => {
    state.errorSendSms = payload;
  },
  setLimiterSendSms: (state, payload) => {
    state.limiterSendSms = payload;
  },
};

const actions = {
  getTypesOfCars({ commit }) {
    commonApi
      .getTypesOfCars()
      .then((res) => {
        commit("setTypesOfCars", res.data);
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      });
  },
  sendSms({ commit }, payload) {
    commit("setErrorSendSms", []);
    commonApi
      .sendSms(payload)
      .then((res) => {
        commit("setLimiterSendSms", true);
        setTimeout(() => {
          commit("setLimiterSendSms", false);
        }, 61000);
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setErrorSendSms", data);
      });
  },
  sendSmsDriver({ commit }, { url, data }) {
    commonApi
      .sendSmsDrivers(url, data)
      .then((res) => {
        console.log(res);
        commit("setDriverChooseNull");
      })
      .catch((error) => {});
  },
  getCargoFeatures({ commit }) {
    commonApi
      .getCargoFeatures()
      .then((res) => {
        commit("setCargoFeatures", res.data);
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      });
  },
  getBodyTypes({ commit }) {
    commonApi
      .getBodyTypes()
      .then((res) => {
        commit("setBodyTypes", res.data);
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      });
  },
  getBrands({ commit }) {
    commonApi
      .getBrands()
      .then((res) => {
        commit("setBrands", res.data);
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      });
  },
  getLoadingTypes({ commit }) {
    commonApi
      .getLoadingTypes()
      .then((res) => {
        commit("setLoadingTypes", res.data);
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      });
  },
};

const getters = {};

const common = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
export default common;
