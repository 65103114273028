export default {
  //login
  //
  LOG_IN: "/login",
  LOG_OUT: "/logout",

  //common
  //
  GET_BODY_TYPES: "/getBodyTypes",
  GET_BRANDS: "/getBrands",
  GET_TYPES_OF_CARS: "/getTypesOfCars",
  GET_CARGO_FEATURES: "/getCargoFeatures",
  GET_STATUSES: "/v2/admin/statuses",
  GEO_SEARCH: "/geoSearch",
  SEND_SMS: "/sendSms",
  LOADING_TYPES: "/v2/loading",
  PAYMENT_TYPES: "/v2/paymentTypes",

  //drivers
  //
  GET_DRIVERS_LIST: "/getDrivers",
  GET_DRIVERS_FOR_ORDER: "/getDriversForOrder",
  GET_DRIVER_ACTIVE_ORDER: "/getDriverActiveOrder",
  GET_DRIVER: "/getDriver",
  APPROVE_DRIVER: "/approveDriver",
  DRIVER_DELETE: "/deleteDriver",
  STORE_DRIVER: "/storeDriver",
  DRIVER_UPDATE: "/editDriver",
  RESTORE_DRIVER: "/restoreDriver",
  CONFIRM_DRIVER_CHANGES: "/confirmDriverChanges",

  //clients
  //
  GET_CLIENTS_LIST: "/getClients",
  GET_CLIENT_DETAIL: "/v2/admin/clients",
  CLIENT_CREATE: "/storeClient",
  CLIENT_UPDATE: "/editClient",
  CLIENT_DELETE: "/deleteClient",

  //order
  //
  GET_ORDERS_LIST: "/v2/admin/orders",
  GET_ORDER_DETAIL: "/getOrder",
  ORDER_CREATE: "/storeOrder",
  ORDER_UPDATE: "/v2/admin/orders/edit",
  ORDER_CANCEL: "/cancelOrder",
  ASSIGN_DRIVER_TO_ORDER: "/assignDriverToOrder",
  RE_ASSIGN_DRIVER_TO_ORDER: "/reAssignDriverToOrder",
  GET_ORDERS_FOR_DRIVER: "/v2/admin/orders/forDriver",
  SET_ORDERS_COMPLETED: "/v2/admin/orders/completed",

  //settings
  //
  GET_SETTINGS: "/v2/admin/settings",
  UPDATE_SETTINGS: "/v2/admin/settings",

  //notifications
  //
  GET_NOTIFICATIONS: "/v2/admin/notifications",
  NOTIFICATION_READ: "/v2/admin/notifications/read",
  NOTIFICATION_READ_ALL: "/v2/admin/notifications/read/all",
  GET_NOTIFICATIONS_TYPES: "/v2/admin/notifications/types",
  NOTIFICATION_VIEWED: "/v2/admin/notifications/viewed",
};
