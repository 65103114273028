import Vue from "vue";
import VueRouter from "vue-router";
import ROUTE from "../constants/routes";
import { getStoredUserInfo } from "../helpers/userAuth";

const Login = () => import("../pages/Login.vue");
const Ether = () => import("../pages/Ether.vue");
const Clients = () => import("../pages/Clients.vue");
const ClientCreate = () => import("../pages/ClientCreate.vue");
const ClientDetail = () => import("../pages/ClientDetail.vue");
const Orders = () => import("../pages/Orders.vue");
const OrderCreate = () => import("../pages/OrderCreate.vue");
const OrderDetail = () => import("../pages/OrderDetail.vue");
const SuccessOrder = () => import("../pages/SuccessOrder.vue");
const Drivers = () => import("../pages/Drivers.vue");
const DriverDetail = () => import("../pages/DriverDetail.vue");
const DriverCreate = () => import("../pages/DriverCreate.vue");
const Settings = () => import("../pages/Settings.vue");
const Notifications = () => import("../pages/NotificationsPage.vue");

Vue.use(VueRouter);

const checkPrivateRoute = (to, from, next) => {
  if (getStoredUserInfo() === null) return next(ROUTE.LOGIN_PAGE);
  return next();
};

const routes = [
  {
    path: ROUTE.ETHER_PAGE,
    name: "Ether",
    component: Ether,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },
  {
    path: ROUTE.ETHER_ADD_DRIVER_PAGE,
    name: "EtherAddDriver",
    component: Ether,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },
  {
    path: ROUTE.ETHER_ADD_ORDER_PAGE,
    name: "EtherAddOrder",
    component: Ether,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },
  {
    path: ROUTE.LOGIN_PAGE,
    name: "Login",
    component: Login,
  },
  {
    path: ROUTE.DRIVERS_PAGE,
    name: "Drivers",
    component: Drivers,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },
  {
    path: ROUTE.DRIVER_DETAIL_PAGE,
    name: "DriverDetail",
    component: DriverDetail,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },
  {
    path: ROUTE.DRIVER_CREATE_PAGE,
    name: "DriverCreate",
    component: DriverCreate,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },
  {
    path: ROUTE.ORDER_DETAIL_PAGE,
    name: "OrderDetail",
    component: OrderDetail,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },
  {
    path: ROUTE.ORDER_CREATE_PAGE,
    name: "OrderCreate",
    component: OrderCreate,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },
  {
    path: ROUTE.ORDERS_PAGE,
    name: "Orders",
    component: Orders,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },
  {
    path: ROUTE.ORDER_SUCCESS,
    name: "SuccessOrder",
    component: SuccessOrder,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },
  {
    path: ROUTE.CLIENTS_PAGE,
    name: "Clients",
    component: Clients,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },
  {
    path: ROUTE.CLIENT_DETAIL_PAGE,
    name: "ClientDetail",
    component: ClientDetail,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },
  {
    path: ROUTE.CLIENT_CREATE_PAGE,
    name: "ClientCreate",
    component: ClientCreate,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },
  {
    path: ROUTE.SETTINGS_PAGE,
    name: "Settings",
    component: Settings,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },
  {
    path: ROUTE.NOTIFICATIONS_PAGE,
    name: "Notification",
    component: Notifications,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },
];

const router = new VueRouter({
  mode: "history",

  base: process.env.BASE_URL,

  routes,
});

export default router;
