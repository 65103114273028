<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  mounted() {
    console.log(
      "%cЭтот раздел для разработчиков! Просьба, не вставлять здесь никакой код!",
      "background: #222; color: #bada55; font-size: 30px",
    );
  },
};
</script>

<style lang="scss"></style>
