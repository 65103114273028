import callApi from "./index";
import URL from "../constants/urlPaths";

export default {
  getTypesOfCars() {
    return callApi
      .request({
        method: "GET",
        url: URL.GET_TYPES_OF_CARS,
      })
      .then((res) => res.data);
  },
  geoSearch(url) {
    return callApi
      .request({
        method: "POST",
        url: URL.GEO_SEARCH + url,
      })
      .then((res) => res.data);
  },
  getCargoFeatures() {
    return callApi
      .request({
        method: "GET",
        url: URL.GET_CARGO_FEATURES,
      })
      .then((res) => res.data);
  },
  getBodyTypes() {
    return callApi
      .request({
        method: "GET",
        url: URL.GET_BODY_TYPES,
      })
      .then((res) => res.data);
  },
  getBrands() {
    return callApi
      .request({
        method: "GET",
        url: URL.GET_BRANDS,
      })
      .then((res) => res.data);
  },
  getStatuses() {
    return callApi
      .request({
        method: "GET",
        url: URL.GET_STATUSES,
      })
      .then((res) => res.data);
  },
  sendSms(data) {
    return callApi
      .request({
        method: "POST",
        url: URL.SEND_SMS,
        data,
      })
      .then((res) => res.data);
  },
  getLoadingTypes(data) {
    return callApi
      .request({
        method: "GET",
        url: URL.LOADING_TYPES,
        data,
      })
      .then((res) => res.data);
  },
  getPaymentTypes() {
    return callApi
      .request({
        method: "GET",
        url: URL.PAYMENT_TYPES,
      })
      .then((res) => res.data);
  },
  sendSmsDrivers(url, data) {
    return callApi
      .request({
        method: "POST",
        url: `/v2/admin/orders${url}`,
        data,
      })
      .then((res) => res.data);
  },
};
