import { dateTransform } from "../helpers/dateHelper";

export const driversList = (data) => {
  const driversList = [];
  if (data) {
    for (let i = 0; i < data.length; i++) {
      const list = {
        id: data[i].id,
        last_name: `${data[i].lastName} ${data[i].firstName} ${data[i].patronymic}`,
        phone: data[i].phone,
        drivers_license_series: `${data[i].driversLicenseSeries} ${data[i].driversLicenseNumber}`,
        type_of_car_id: data[i].car?.typeOfCar?.name,
        body_type_id: data[i].car?.bodyType?.name,
        brand_id: data[i].car?.brand?.name,
        model: data[i].car?.model,
        car_number: data[i].car?.carNumber,
        year_of_car_manufacture: data[i].car?.yearOfCarManufacture,
        trailer_number: data[i].car?.trailerNumber,
        year_of_trailer_manufacture: data[i].car?.yearOfTrailerManufacture,
        status: data[i].status,
        free: data[i].free,
        register_at: data[i].registerAt,
      };
      driversList.push(list);
    }
  }
  return driversList;
};

export const clientsList = (data) => {
  const driversList = [];
  if (data) {
    for (let i = 0; i < data.length; i++) {
      const list = {
        id: data[i].id,
        name: data[i].name,
        phone: data[i].phone,
        created_at: dateTransform(data[i].createdAt),
        total_amount: data[i].totalAmount,
        orders_count: data[i].ordersCount,
      };
      driversList.push(list);
    }
  }
  return driversList;
};

export const ordersList = (data) => {
  const ordersList = [];
  if (data) {
    for (let i = 0; i < data.length; i++) {
      const list = {
        id: data[i].id,
        address_from_short: `${data[i].addressFromShort} \n${data[i].addressToShort}`,
        driver_last_name:
          data[i].driver != null
            ? `${data[i].driver?.lastName} ${data[i].driver?.firstName} ${data[i].driver?.patronymic}`
            : null,
        driver_id: data[i].driver != null ? data[i].driver.id : null,
        driver_phone: data[i].driver?.phone,
        client_name: data[i].client?.name,
        client_phone: data[i].client?.phone,
        date: data[i].date,
        time: data[i].time,
        rate: data[i].rate,
        total_price: data[i].totalPrice,
        status: data[i].status,
        type_of_car_id: data[i].typeOfCar?.name,
        address_to: data[i].addressToShort,
        address_from: data[i].addressFromShort,
      };
      ordersList.push(list);
    }
  }
  return ordersList;
};

export const ordersListReverse = (obj, data) => {
  obj.id = data.client?.id;
  obj.name = data.client?.name;
  obj.addressFrom = {
    address: {
      road: data.addressFromShort,
      houseNumber: null,
    },
    lat: data.latitudeFrom,
    lon: data.longitudeFrom,
    displayName: data.addressFromShort,
  };
  obj.addressTo = {
    address: {
      road: data.addressToShort,
      houseNumber: null,
    },
    lat: data.latitudeTo,
    lon: data.longitudeTo,
    displayName: data.addressToShort,
  };
  obj.date = data.date;
  obj.time = {
    name: data.time,
  };
  obj.countOfHours = data.countOfHours;
  obj.minutes = data.countOfMinutes == 0 ? "00" : data.countOfMinutes;
  obj.rate = data.rate;
  obj.insurance = data.insurance;
  obj.isCity = data.isCity;
  obj.summ = data.totalPrice;
  obj.costEstimate = data.costEstimate;
  obj.numberOfMovers = data.numberOfMovers === null ? 0 : data.numberOfMovers;
  obj.description = data.description;
  obj.typesOfCars = data.typeOfCar;
  obj.distance = data.distance;
  obj.dispatcherPhone = data.dispatcherPhone;
  obj.cargoName = data.cargoName;
  obj.bodyTypeName = data.bodyType?.id ? data.bodyType.id : null;
  obj.status = data.status;
  obj.paymentType = data.paymentType;
  if (data.typeOfCar.id == 5) {
    obj.weight = data.weight;
    obj.length = data.length;
    obj.width = data.width;
    obj.height = data.height;
    obj.volume = data.volume;
  }
  if (data.links.length > 0) {
    obj.linkUrl = data.links[0].url;
  }
  return obj;
};

export const phoneNumberIteration = (phone) => {
  const phoneValid = phone
    .replace(/\s+/g, "")
    .replaceAll(".", "")
    .replaceAll("-", "")
    .replaceAll("(", "")
    .replaceAll(")", "");
  return phoneValid;
};

export const orderSendData = (data) => {
  console.log(data);
  const dataN = {
    client_id: data.id,
    address_from:
      data.addressFrom?.address.road != undefined && data.addressFrom?.address.road != null
        ? `${
            data.addressFrom?.address.village != undefined &&
            data.addressFrom?.address.village != null
              ? data.addressFrom?.address.village
              : ""
          }${
            data.addressFrom?.address.city != undefined && data.addressFrom?.address.city != null
              ? data.addressFrom?.address.city
              : ""
          }${
            data.addressFrom?.address.town != undefined && data.addressFrom?.address.town != null
              ? data.addressFrom?.address.town
              : ""
          }${
            (data.addressFrom?.address.city != undefined &&
              data.addressFrom?.address.city != null) ||
            (data.addressFrom?.address.town != undefined &&
              data.addressFrom?.address.town != null) ||
            (data.addressFrom?.address.village != undefined &&
              data.addressFrom?.address.village != null)
              ? ", "
              : ""
          }${data.addressFrom?.address.road}${
            (data.addressFrom?.address.houseNumber != undefined &&
              data.addressFrom?.address.houseNumber != null) ||
            data.numberHouseFrom != null
              ? ", "
              : ""
          }${
            data.addressFrom?.address.houseNumber != undefined &&
            data.addressFrom?.address.houseNumber != null
              ? data.addressFrom?.address.houseNumber
              : data.numberHouseFrom != null
              ? data.numberHouseFrom
              : ""
          }`
        : "",
    address_to:
      data.addressTo?.address.road != undefined && data.addressTo?.address.road != null
        ? `${
            data.addressTo?.address.village != undefined && data.addressTo?.address.village != null
              ? addressTo.addressFrom?.address.village
              : ""
          }${
            data.addressTo?.address.city != undefined && data.addressTo?.address.city != null
              ? data.addressTo?.address.city
              : ""
          }${
            data.addressTo?.address.town != undefined && data.addressTo?.address.town != null
              ? data.addressTo?.address.town
              : ""
          }${
            (data.addressTo?.address.city != undefined && data.addressTo?.address.city != null) ||
            (data.addressTo?.address.town != undefined && data.addressTo?.address.town != null) ||
            (data.addressTo?.address.village != undefined &&
              data.addressTo?.address.village != null)
              ? ", "
              : ""
          }${data.addressTo?.address.road}${
            (data.addressTo?.address.houseNumber != undefined &&
              data.addressTo?.address.houseNumber != null) ||
            data.numberHouseTo != null
              ? ", "
              : ""
          }${
            data.addressTo?.address.houseNumber != undefined &&
            data.addressTo?.address.houseNumber != null
              ? data.addressTo?.address.houseNumber
              : data.numberHouseTo != null
              ? data.numberHouseTo
              : ""
          }`
        : "",
    address_from_short: "",
    address_to_short: "",
    longitude_from: data.addressFrom?.lon || "",
    latitude_from: data.addressFrom?.lat || "",
    longitude_to: data.addressTo?.lon || "",
    latitude_to: data.addressTo?.lat || "",
    insurance: data.insurance,
    date_time: `${data.date} ${data.time?.name}:00`,
    count_of_hours: data.countOfHours,
    count_of_minutes: +data.minutes,
    rate: data.rate,
    type_of_car_id: data.typesOfCars?.id,
    dispatcher_phone: data.dispatcherPhone,
    cargo_name: data.cargoName || "",
    is_city: data.isCity,
    total_price: data.summ,
    body_type_id: data.bodyTypeName,
  };
  dataN.address_from_short = dataN.address_from;
  dataN.address_to_short = dataN.address_to;
  if (data.distance != null) {
    dataN.distance = +data.distance;
  }

  const loadingId = Object.keys(data.loadingTypes);
  let loadingIdSend = [];
  for (let i = 0; loadingId.length > i; i++) {
    if (data.loadingTypes[loadingId[i]] == true) {
      loadingIdSend.push(loadingId[i]);
    }
  }
  console.log(loadingIdSend);
  if (loadingIdSend.length > 0) {
    dataN.loading_ids = loadingIdSend;
  }

  if (data.numberOfMovers != 0) {
    dataN.number_of_movers = data.numberOfMovers;
  }
  if (data.insurance === true) {
    dataN.cost_estimate = data.costEstimate;
  }
  if (data.description != 0) {
    dataN.description = data.description;
  }
  const feature = Object.keys(data.cargoFeatures);
  const featureArray = [];
  for (let i = 0; i < feature.length; i++) {
    if (data.cargoFeatures[feature[i]] != false) {
      featureArray.push(feature[i]);
    }
  }
  if (featureArray.length) {
    dataN.cargo_features = featureArray;
  }
  if (data.weight) {
    dataN.weight = data.weight;
  }
  if (data.weight) {
    dataN.length = data.length;
  }
  if (data.width) {
    dataN.width = data.width;
  }
  if (data.height) {
    dataN.height = data.height;
  }
  if (data.volume) {
    dataN.volume = data.volume;
  }
  if (data.linkUrl.length > 0) {
    dataN.links = [
      {
        name: "google_map",
        url: data.linkUrl,
      },
    ];
  }

  if (data.status != null) {
    if (data.status.name) {
      dataN.status = data.status.name;
    }

    if (data.status.key) {
      dataN.status = data.status.key;
    }
  }

  if (data.paymentType != null) {
    dataN.payment_type_id = data.paymentType.id;
  }

  return dataN;
};

export const driverListReverse = (obj, data) => {
  obj.firstName = data.firstName;
  obj.lastName = data.lastName;
  obj.patronymic = data.patronymic;
  obj.phone = data.phone;
  obj.driversLicenseSeries = data.driversLicenseSeries;
  obj.driversLicenseNumber = data.driversLicenseNumber;
  obj.bodyType = data?.car?.bodyType;
  obj.brand = data?.car?.brand;
  obj.model = data?.car?.model;
  obj.carNumber = data?.car?.carNumber;
  obj.yearOfCarManufacture = { name: data?.car?.yearOfCarManufacture };
  obj.typesOfCars = data?.car?.typeOfCar;
  obj.urlFrontDriversLicensePhoto = data?.frontDriversLicensePhoto?.photoUrl;
  obj.urlBackDriversLicensePhoto = data?.backDriversLicensePhoto?.photoUrl;
  obj.urlCarRegistrationCertificatePhoto = data?.car?.carRegistrationCertificatePhoto?.photoUrl;
  obj.urlTrailerRegistrationCertificatePhoto =
    data?.car?.trailerRegistrationCertificatePhoto != null
      ? data?.car?.trailerRegistrationCertificatePhoto?.photoUrl
      : null;
  obj.urlFrontPhoto = data?.car?.frontPhoto?.photoUrl;
  obj.urlLeftPhoto = data?.car?.leftPhoto?.photoUrl;
  obj.urlRightPhoto = data?.car?.rightPhoto?.photoUrl;
  obj.urlBackPhoto = data?.car?.backPhoto?.photoUrl;
  obj.city = data.city;
  obj.fullRegistration = data.fullRegistration;

  if (data?.car?.trailerNumber != null) {
    obj.trailerNumber = data.car.trailerNumber;
    obj.yearOfTrailerManufacture = { name: data.car.yearOfTrailerManufacture };
  }

  if (data?.car?.typeOfCar?.id == 5) {
    obj.weight = data.car.weight;
    obj.length = data.car.length;
    obj.width = data.car.width;
    obj.height = data.car.height;
    obj.volume = data.car.volume;
  }
  return obj;
};

export const driverSendData = (data, create = true) => {
  let dataN = new FormData();
  dataN.append("last_name", data.lastName);
  dataN.append("first_name", data.firstName);
  dataN.append("patronymic", data.patronymic);
  dataN.append("drivers_license_series", data.driversLicenseSeries);
  dataN.append("drivers_license_number", data.driversLicenseNumber);
  dataN.append("car[brand_id]", data.brand?.id);
  dataN.append("car[model]", data.model);
  dataN.append("car[type_of_car_id]", data.typesOfCars?.id);
  dataN.append("car[body_type_id]", data.bodyType?.id);
  dataN.append("car[car_number]", data.carNumber);
  dataN.append("car[year_of_car_manufacture]", data.yearOfCarManufacture?.name);
  dataN.append("city", data.city);
  dataN.append("full_registration", !!data.fullRegistration == true ? 1 : 0);

  if (data.trailerNumber != undefined && data?.trailerNumber?.length > 0) {
    dataN.append("car[trailer_number]", data.trailerNumber);
    dataN.append("car[year_of_trailer_manufacture]", data.yearOfTrailerManufacture?.name);
  }

  if (create) {
    dataN.append("code", data.code.join(""));
  }

  if (data.phone != undefined && data?.phone?.length > 0 && create) {
    dataN.append("phone", phoneNumberIteration(data.phone));
  }

  if (data.frontDriversLicensePhoto != undefined) {
    dataN.append("front_drivers_license_photo", data.frontDriversLicensePhoto);
  }

  if (data.backDriversLicensePhoto != undefined) {
    dataN.append("back_drivers_license_photo", data.backDriversLicensePhoto);
  }

  if (data.carRegistrationCertificatePhoto != undefined) {
    dataN.append("car[car_registration_certificate_photo]", data.carRegistrationCertificatePhoto);
  }

  if (data.trailerRegistrationCertificatePhoto != undefined) {
    dataN.append(
      "car[trailer_registration_certificate_photo]",
      data.trailerRegistrationCertificatePhoto,
    );
  }

  if (data.frontPhoto != undefined) {
    dataN.append("car[front_photo]", data.frontPhoto);
  }

  if (data.backPhoto != undefined) {
    dataN.append("car[back_photo]", data.backPhoto);
  }

  if (data.rightPhoto != undefined) {
    dataN.append("car[right_photo]", data.rightPhoto);
  }

  if (data.leftPhoto != undefined) {
    dataN.append("car[left_photo]", data.leftPhoto);
  }

  if (data.weight) {
    dataN.append("car[weight]", data.weight);
  }
  if (data.weight) {
    dataN.append("car[length]", data.length);
  }
  if (data.width) {
    dataN.append("car[width]", data.width);
  }
  if (data.height) {
    dataN.append("car[height]", data.height);
  }
  if (data.volume) {
    dataN.append("car[volume]", data.volume);
  }

  return dataN;
};
