import callApi from "./index";
import URL from "../constants/urlPaths";

export default {
  clientCreate(data) {
    return callApi
      .request({
        method: "POST",
        url: URL.CLIENT_CREATE,
        data,
      })
      .then((res) => res.data);
  },
  clientUpdate(url, data) {
    return callApi
      .request({
        method: "POST",
        url: URL.CLIENT_UPDATE + url,
        data,
      })
      .then((res) => res.data);
  },
  getClientList(url = "") {
    return callApi
      .request({
        method: "GET",
        url: URL.GET_CLIENTS_LIST + url,
      })
      .then((res) => res.data);
  },
  deleteClient(url) {
    return callApi.request({
      method: "DELETE",
      url: URL.CLIENT_DELETE + url,
    });
  },
  getClientDetail(url) {
    return callApi
      .request({
        method: "GET",
        url: URL.GET_CLIENT_DETAIL + url,
      })
      .then((res) => res.data);
  },
};
