/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import Vue from "vue";
import Vuex from "vuex";
import ordersApi from "../api/order";
import router from "../router";
import ROUTE from "../constants/routes";
import { ordersList } from "../helpers/dataIteration";

Vue.use(Vuex);

const state = {
  error: {},
  order: null,
  orderHistory: [],
  orders: null,
  ordersList: [],
  loadingProcess: false,
};

const mutations = {
  setError: (state, payload) => {
    state.error = payload;
  },
  setLoading: (state, payload) => {
    state.loadingProcess = payload;
  },
  setOrders: (state, payload) => {
    state.orders = payload;
  },
  setOrdersList: (state, payload) => {
    state.ordersList = payload;
  },
  setOrder: (state, payload) => {
    state.order = payload;
  },
  setNewOrders: (state, data) => {
    if (state.orders != null) {
      const i = state.orders.data?.findIndex((o) => o.id == data.id);
      if (state.orders.data[i]) {
        state.orders.data?.splice(i, 1, data);
      } else {
        state.orders.data?.unshift(data);
      }
    }
  },
  setDeleteOrder: (state, data) => {
    if (state.orders != null) {
      const i = state.orders.data?.findIndex((o) => o.id == data.id);
      if (state.orders?.data[i]) {
        state.orders.data?.splice(i, 1);
      }
      if (state.order?.id == data?.id) {
        state.order = null;
      }
    }
  },
  setOrderHistory: (state, payload) => {
    state.orderHistory = payload;
  },
};

const actions = {
  createOrder({ commit }, payload) {
    commit("setLoading", true);
    ordersApi
      .orderCreate(payload)
      .then((res) => {
        commit("setError", {});
        router.push({
          path: ROUTE.ORDER_SUCCESS.replace(":orderId", res.data.id),
        });
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  updateOrder({ commit }, { url, dataOrder }) {
    commit("setLoading", true);
    ordersApi
      .orderUpdate(url, dataOrder)
      .then((res) => {
        commit("setError", {});
        router.push({
          path: ROUTE.ORDER_SUCCESS.replace(":orderId", res.data.id),
        });
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  getOrderHistory({ commit }, payload) {
    commit("setLoading", true);
    ordersApi
      .getOrderHistory(payload)
      .then((res) => {
        commit("setOrderHistory", res.data);
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  finishOrder({ commit }, payload) {
    commit("setLoading", true);
    ordersApi
      .finishOrder(payload)
      .then((res) => {})
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  removeDriver({ commit }, payload) {
    commit("setLoading", true);
    ordersApi
      .removeDriver(payload)
      .then((res) => {})
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  getOrdersList({ commit }, payload) {
    commit("setLoading", true);
    ordersApi
      .getOrdersList(payload)
      .then((res) => {
        commit("setOrders", res);
        commit("setOrdersList", ordersList(res.data));
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  getOrdersForDriver({ commit }, payload) {
    commit("setLoading", true);
    ordersApi
      .getOrdersForDriver(payload)
      .then((res) => {
        commit("setOrders", res);
        commit("setOrdersList", ordersList(res?.data));
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setErrorDriver", data.errors[0]);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  cancelOrder({ commit, dispatch }, { url, dataSend }) {
    commit("setLoading", true);
    ordersApi
      .cancelOrder(url, dataSend)
      .then((res) => {})
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      });
  },
  getOrderDetail({ commit }, payload) {
    commit("setLoading", true);
    ordersApi
      .getOrderDetail(payload)
      .then((res) => {
        commit("setOrder", res.data);
      })
      .catch((error) => {
        const { data } = error.response;
        commit("serError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  assignDriverToOrder({ commit }, payload) {
    ordersApi
      .assignDriverToOrder(payload)
      .then((res) => {
        commit("setError", {});
        router.push({
          path: ROUTE.ORDER_SUCCESS.replace(":orderId", res.data.id),
        });
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data.data);
      })
      .finally(() => {});
  },
  reAssignDriverToOrder({ commit }, payload) {
    ordersApi
      .reAssignDriverToOrder(payload)
      .then((res) => {
        commit("setError", {});
        router.push({
          path: ROUTE.ORDER_SUCCESS.replace(":orderId", res.data.id),
        });
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data.data);
      })
      .finally(() => {});
  },
};

const getters = {};

const orders = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
export default orders;
