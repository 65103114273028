/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import Vue from "vue";
import Vuex from "vuex";
import clientsApi from "../api/clients";
import router from "../router";
import ROUTE from "../constants/routes";
import { clientsList } from "../helpers/dataIteration";

Vue.use(Vuex);

const state = {
  error: {},
  client: null,
  clients: {},
  clientsList: [],
  loadingProcess: false,
};

const mutations = {
  setError: (state, payload) => {
    state.error = payload;
  },
  setLoading: (state, payload) => {
    state.loadingProcess = payload;
  },
  setClients: (state, payload) => {
    state.clients = payload;
  },
  setClientsList: (state, payload) => {
    state.clientsList = payload;
  },
  setClient: (state, payload) => {
    state.client = payload;
  },
};

const actions = {
  createClient({ commit }, payload) {
    commit("setLoading", true);
    clientsApi
      .clientCreate(payload)
      .then((res) => {
        if (res.name || res.phone) {
          commit("setError", res);
          return;
        }
        commit("setError", {});
        router.push(ROUTE.ETHER_PAGE);
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  updateClient({ commit }, url, dataClient) {
    commit("setLoading", true);
    clientsApi
      .clientUpdate(url, dataClient)
      .then((res) => {
        if (res.name || res.phone) {
          commit("setError", res);
          return;
        }
        commit("setError", {});
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  getClientsList({ commit }, payload) {
    commit("setLoading", true);
    clientsApi
      .getClientList(payload)
      .then((res) => {
        commit("setClients", res);
        commit("setClientsList", clientsList(res.data));
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  deleteClient({ commit, dispatch }, payload) {
    commit("setLoading", true);
    clientsApi
      .deleteClient(payload)
      .then((res) => {})
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      });
  },
  getClientDetail({ commit }, payload) {
    commit("setLoading", true);
    clientsApi
      .getClientDetail(payload)
      .then((res) => {
        commit("setClient", res.data);
      })
      .catch((error) => {
        const { data } = error.response;
        commit("serError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
};

const getters = {};

const clients = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
export default clients;
