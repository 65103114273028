import callApi from "./index";
import URL from "../constants/urlPaths";

export default {
  getDriversList(url) {
    return callApi
      .request({
        method: "GET",
        url: URL.GET_DRIVERS_LIST + url,
      })
      .then((res) => res.data);
  },
  driverCreate(data) {
    return callApi
      .request({
        headers: {
          "Content-Type": "multipart/form-data",
        },
        method: "POST",
        url: URL.STORE_DRIVER,
        data,
      })
      .then((res) => res.data);
  },
  driverUpdate(url, data) {
    return callApi
      .request({
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        url: URL.DRIVER_UPDATE + url,
        data,
      })
      .then((res) => res.data);
  },
  getDriversForOrder(url) {
    return callApi
      .request({
        method: "GET",
        url: URL.GET_DRIVERS_FOR_ORDER + url,
      })
      .then((res) => res.data);
  },
  getDriverActiveOrder(url) {
    return callApi
      .request({
        method: "GET",
        url: URL.GET_DRIVER_ACTIVE_ORDER + url,
      })
      .then((res) => res.data);
  },
  getDriver(url) {
    return callApi
      .request({
        method: "GET",
        url: URL.GET_DRIVER + url,
      })
      .then((res) => res.data);
  },
  approveDriver(url, data) {
    return callApi
      .request({
        method: "POST",
        url: URL.APPROVE_DRIVER + url,
        data,
      })
      .then((res) => res.data);
  },
  confirmDriverChanges(url) {
    return callApi
      .request({
        method: "POST",
        url: URL.CONFIRM_DRIVER_CHANGES + url,
      })
      .then((res) => res.data);
  },
  deleteDriver(url) {
    return callApi
      .request({
        method: "DELETE",
        url: URL.DRIVER_DELETE + url,
      })
      .then((res) => res.data);
  },
  driverRestore(url) {
    return callApi
      .request({
        method: "POST",
        url: URL.RESTORE_DRIVER + url,
      })
      .then((res) => res.data);
  },
};
