/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import Vue from "vue";
import Vuex from "vuex";
import settingsApi from "../api/settings";

Vue.use(Vuex);

const state = {
  settings: null,
  loadingProcess: false,
  error: {},
};

const mutations = {
  setSettings: (state, payload) => {
    state.settings = payload;
  },
  setError: (state, payload) => {
    state.error = payload;
  },
  setLoading: (state, payload) => {
    state.loadingProcess = payload;
  },
};

const actions = {
  updateSettings({ commit }, payload) {
    commit("setLoading", true);
    settingsApi
      .updateSettings(payload)
      .then((res) => {
        commit("setError", {});
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  getSettings({ commit }, payload) {
    commit("setLoading", true);
    settingsApi
      .getSettings()
      .then((res) => {
        commit("setSettings", res);
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
};

const getters = {};

const settings = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
export default settings;
