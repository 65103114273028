import callApi from "./index";
import URL from "../constants/urlPaths";

export default {
  orderCreate(data) {
    return callApi
      .request({
        method: "POST",
        url: URL.ORDER_CREATE,
        data,
      })
      .then((res) => res.data);
  },
  orderUpdate(url, data) {
    return callApi
      .request({
        method: "POST",
        url: URL.ORDER_UPDATE + url,
        data,
      })
      .then((res) => res.data);
  },
  getOrdersList(url = "") {
    return callApi
      .request({
        method: "GET",
        url: URL.GET_ORDERS_LIST + url,
      })
      .then((res) => res.data);
  },
  getOrderHistory(url) {
    return callApi
      .request({
        method: "GET",
        url: `/v2/admin/orders/${url}/history`,
      })
      .then((res) => res.data);
  },
  getOrdersForDriver(url) {
    return callApi
      .request({
        method: "GET",
        url: URL.GET_ORDERS_FOR_DRIVER + url,
      })
      .then((res) => res.data);
  },
  cancelOrder(url, data) {
    return callApi.request({
      method: "POST",
      url: URL.ORDER_CANCEL + url,
      data,
    });
  },
  getOrderDetail(url) {
    return callApi
      .request({
        method: "GET",
        url: URL.GET_ORDER_DETAIL + url,
      })
      .then((res) => res.data);
  },
  assignDriverToOrder(data) {
    return callApi
      .request({
        method: "POST",
        url: URL.ASSIGN_DRIVER_TO_ORDER,
        data,
      })
      .then((res) => res.data);
  },
  reAssignDriverToOrder(data) {
    return callApi
      .request({
        method: "POST",
        url: URL.RE_ASSIGN_DRIVER_TO_ORDER,
        data,
      })
      .then((res) => res.data);
  },
  finishOrder(id) {
    return callApi
      .request({
        method: "POST",
        url: `/v2/admin/orders/${id}/finish`,
      })
      .then((res) => res.data);
  },
  removeDriver(id) {
    return callApi
      .request({
        method: "POST",
        url: `/v2/admin/orders/${id}/driver/remove`,
      })
      .then((res) => res.data);
  },
  sendFinishOrder(data) {
    return callApi
      .request({
        method: "POST",
        url: URL.SET_ORDERS_COMPLETED,
        data,
      })
      .then((res) => res.data);
  },
};
