/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import Vue from "vue";
import Vuex from "vuex";
import notificationsApi from "../api/notifications";

Vue.use(Vuex);

const state = {
  notificationAll: 0,
  notificationDriverModerated: 0,
  notificationDriverModeratedAfterEdit: 0,
  notificationOrder: 0,
  notificationsList: [],
  notifications: null,
  error: {},
  loadingProcess: false,
  notificationsTypes: [],
};

const mutations = {
  setNotificationAll: (state, payload) => {
    state.notificationAll = payload;
  },
  setNotificationDriverModerated: (state, payload) => {
    state.notificationDriverModerated = payload;
  },
  setNotificationDriverModeratedAfterEdit: (state, payload) => {
    state.notificationDriverModeratedAfterEdit = payload;
  },
  setNotificationOrder: (state, payload) => {
    state.notificationOrder = payload;
  },
  setNotificationsList: (state, payload) => {
    state.notificationsList = payload;
  },
  setNotifications: (state, payload) => {
    state.notifications = payload;
  },
  setError: (state, payload) => {
    state.error = payload;
  },
  setLoading: (state, payload) => {
    state.loadingProcess = payload;
  },
  setNewNotification: (state, payload) => {
    state.notificationsList = payload;
  },
  setNotificationsTypes: (state, payload) => {
    state.notificationsTypes = payload;
  },
};

const actions = {
  getNewNotification: ({ commit, state }, { data, trigger }) => {
    if (trigger) {
      const notificationsList = state.notificationsList;
      notificationsList.unshift(data);
      commit("setNewNotification", notificationsList);
    }
  },
  plusCountNotification: ({ commit, state }, { data, trigger }) => {
    if (trigger) {
      commit("setNotificationAll", state.notificationAll + 1);
    }
    if (data.type === "order_created") {
      commit("setNotificationOrder", +state.notificationOrder + 1);
    }
  },
  getNotificationsList({ commit }, payload) {
    commit("setLoading", true);
    notificationsApi
      .getNotifications(payload)
      .then((res) => {
        commit("setNotifications", res);
        commit("setNotificationsList", res.data);
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  notificationRead({ commit }, payload) {
    commit("setLoading", true);
    notificationsApi
      .notificationRead(payload)
      .then((res) => {})
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  notificationViewed({ commit }, payload) {
    commit("setLoading", true);
    notificationsApi
      .notificationViewed(payload)
      .then((res) => {})
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  notificationReadAll({ commit }) {
    commit("setLoading", true);
    notificationsApi
      .notificationReadAll()
      .then((res) => {})
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  getNotificationsTypes({ commit }) {
    commit("setLoading", true);
    notificationsApi
      .getNotificationsTypes()
      .then((res) => {
        commit("setNotificationsTypes", res.data[0]);
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
};

const getters = {};

const notification = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
export default notification;
