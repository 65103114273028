import callApi from "./index";
import URL from "../constants/urlPaths";

export default {
  getSettings() {
    return callApi
      .request({
        method: "GET",
        url: URL.GET_SETTINGS,
      })
      .then((res) => res.data);
  },
  updateSettings(data) {
    return callApi
      .request({
        method: "POST",
        url: URL.UPDATE_SETTINGS,
        data,
      })
      .then((res) => res.data);
  },
};
